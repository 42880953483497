body {
  margin: 0;
  padding: 0;
  background-color: #FAFAFA;
  font: 12pt "Arial";
}

body {
  -webkit-print-color-adjust: exact;
}

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.page {
  width: 21cm;
  min-height: 29.7cm;
  padding: 0.3cm;
  margin: 0.3cm auto;
  border: 1px #D3D3D3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.subpage {
  padding: 0.5cm;
  height: 256mm;
  outline: 2cm #ffffff00 solid;
}

@page {
  size: A4;
  margin: 0;
}

@media print {
  .page {
      margin: 0;
      border: initial;
      border-radius: initial;
      width: initial;
      min-height: initial;
      box-shadow: initial;
      background: initial;
      page-break-after: always;
  }
  .actionButtons {
      display: none !important;
  }
}

.actionButtons {
  width: 21cm;
  padding: 1cm;
  margin: 1cm auto;
}